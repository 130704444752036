html,
body {
  -webkit-font-smoothing: antialiased;
}

html {
  width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-family: 'SegoeUI', sans-serif;
  background-color: #fcfcfc;
  -webkit-font-smoothing: antialiased;
}

body.ReactModal__Body--open-reportHelp {
  overflow: hidden;
}
body.ReactModal__Body--open-reportHelp {
  overflow: hidden;
}

/* scrollbar */
::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
  width: 8px;
}
::-webkit-scrollbar:horizontal {
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  border: 2px solid #f0f0f0;
  background-color: #434343;
}
::-webkit-scrollbar-thumb:hover {
  border-color: #434343;
}
::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 4px;
}
* {
  scrollbar-width: auto;
  scrollbar-color: #434343 #f0f0f0;
}

.MuiTooltip-tooltip {
  color: white !important;
}

/* хак, чтобы спрятать странный блок */
/* добавляющийся к body при клике на любой пункт меню */
div[style^='font-size: 14px; font-weight: 200; position: absolute; visibility: hidden; pointer-events: none; left: -10000px;'] {
  display: none;
}

.hidden div[role='grid'] > div:nth-child(1) {
  display: none;
}

.whiteColor {
  color: white !important;
}

.redColor {
  color: #D22F2F !important;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

#form-file-upload {
  padding: 24px 16px 24px 16px;
  border: 1px dashed #0000001F;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  position: relative;
  background-color: #f8fafc;
  margin-top: 10px;
}

#input-file-upload {
  display: none;
}

#label-file-upload p {
  margin: 5px 0;
}
#label-file-upload span {
  color: #6BAC5B;
}

#label-file-upload {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
