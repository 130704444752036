.field-container_fr {
  position: relative;
  width: 100%;
  height: 60px;
  padding: 0 16px;
  border: 1px solid rgba(196,196,196,0.25);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.field-input_fr {
  width: 90%;
  font-size: 16px;
  font-weight: normal;
  outline: none;
  border: none;
  position: absolute;
  top: 35%;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.field-label_fr {
  position: absolute;
  line-height: 14px;
  font-size: 16px;
  color: rgba(82,83,90,0.7);
  font-weight: 200;
  top: 40%;
  left: 16px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.field-input_fr:hover {
  border: none;
}

.withValue .field-label_fr {
  font-size: 14px;
  top: 10px;
}

.withValue .field-input_fr {
  bottom: 10px;
  opacity: 1;
  top: auto;
}

.field-input_fr:focus {
  bottom: 10px;
  opacity: 1;
  top: auto;
}

.field-input_fr:focus + .field-label_fr {
  font-size: 14px;
  top: 10px;
}

.withError {
  border: 1px solid #FF3D00;
}

.withError .field-label_fr {
  color: #FF3D00;
}


