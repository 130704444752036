.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.forced-100-percent-width, .recharts-wrapper {
  width: 100% !important;
  font-family: "SegoeUI";
}

/* toast */

.Toastify__toast-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: end;
      align-items: flex-end;
  width: auto !important;
}

.error-toast-container {
  background: rgba(255, 81, 81, 0.8) !important;
  border-radius: 4px !important;
  max-width: 600px !important;
  word-wrap: break-word;
}

.success-toast-container {
  background: rgba(22, 202, 110, 0.8) !important;
  border-radius: 4px !important;
}

.cancelable-toast-container {
  background: rgba(67, 66, 68, 0.8) !important;
  border-radius: 4px !important;
}

#cadesplugin_ovr {
  z-index: 1001;
}

div[role='tablist'] > button[aria-selected=true] {
  /*color: white;*/
}

.columns-formatter__btn-apply {
  width: 144px;
  height: 40px;
  color: white;
}

.columns-formatter__btn-cancel:focus,
.columns-formatter__btn-apply:focus,
.columns-formatter__btn-show-hide:focus,
.columns-formatter__btn-reorder:focus {
  outline: thin #0287B0 !important;
}

.themeKZGlobal .columns-formatter__btn-apply {
  background: #0287B0 !important;
}

.themeKGGlobal .columns-formatter__btn-apply {
  background: #057EC6 !important;
}

.themeKZGlobal .checkedCustom rect {
  fill: #0287B0 !important;
}

.themeKGGlobal .checkedCustom rect {
  fill: #057EC6 !important;
}

.checkedCustom path {
  fill: #ffffff !important;
}

.themeUZGlobal .columns-formatter__btn-apply {
  background: #67ac5c !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.scanning-field {
  border: 1px solid #c4c4c440;
  width: 100%;
  height: 14rem;
  max-height: 600px;
  margin-bottom: 16px;
  padding: 10px;
}
.scanningContainer {
  position: relative;
}
.scanningContainer .scanning-field {
  resize: none;
  position: absolute;
  background: transparent;
  color: transparent;
  caret-color: black;
  font-size: 0.875rem;
  font-family: 'SegoeUI', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  overflow: hidden;
  border: none;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 0;
  padding: 0;
  outline: none;
  white-space: pre;
}

.scanningContainer__text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  font-family: 'SegoeUI', sans-serif;
  border: 1px solid #000;
  color: black;
  width: 100%;
  height: 14rem;
  max-height: 600px;
  margin-bottom: 16px;
  padding: 20px;
  overflow-y: auto;
  white-space: nowrap;
}

.scanning-field_error {
  color: red;
  font-weight: 600;
}

.scanning-field span {
  color: red;
  font-weight: 600;
}

.status_red {
  color: red;
}

.status_blue {
  color: blue;
}

.status_green {
  color: green;
}

.page-link:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.datePickerButtons button {
  cursor: pointer;
  -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.1);
          box-shadow: 0 3px 4px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 0;
  width: 100px;
  margin: 0 10px;
}

.themeKZGlobal .datePickerButtons button:hover {
  background-color: #0287B0;
  color: white;
}

.themeKGGlobal .datePickerButtons button:hover {
  background-color: #057EC6;
  color: white;
}

.themeUZGlobal .datePickerButtons button:hover {
  background-color: #67ac5c;
  color: white;
}

.productFilter > div {
  z-index: 1201 !important;
}

div[role='row']:hover {
  z-index: 8 !important;
}

.hiddenPagination ul.pagination {
  display: none;
}

.tableSelect {
  width: 100%;
  max-width: 200px;
}

.tableSelect > div > div {
  padding: 13px 16px 0 !important;
  font-size: 16px;
}

.hiddenScrollBar div[role='rowgroup'] > div > div {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.hiddenScrollBar > div[role='rowgroup'] > div > div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
